<template>
  <div class="tags-input-form">
    <div class="heading">
      <div class="text"><slot></slot></div>
    </div>
    <div class="body">
      <div class="wrap">
        <div class="tag" v-for="tag in editOwnerTags" :key="tag.id">
          <base-tag-item :tag="tag" :removable="true" @click="removeTag" />
        </div>
        <button
          class="square-button"
          data-test="open-select"
          @click="openSelect = !openSelect"
        >
          <font-awesome-icon :icon="openSelect ? minusIcon : plusIcon" />
        </button>
      </div>
      <tags-select-box
        v-if="openSelect"
        :tags="ownerTags"
        :errorShowFlg="true"
        @apply-tag="applyTag"
      />
    </div>
  </div>
</template>

<script>
import BaseTagItem from '@/components/parts/atoms/BaseTagItem'
import TagsSelectBox from '@/components/parts/organisms/TagsSelectBox.vue'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'TagsInputForm',

  components: { BaseTagItem, TagsSelectBox, FontAwesomeIcon },

  props: {
    ownerTagIds: { type: Array },
    errorShowFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      editTagIds: [],
      openSelect: false,
      plusIcon: faPlus,
      minusIcon: faMinus
    }
  },

  computed: {
    ...mapGetters({ ownerTags: 'ownerTags/getData' }),
    editOwnerTags() {
      return this.ownerTags.filter(v => this.editTagIds.includes(v.id))
    }
  },

  created() {
    this.editTagIds = [...this.ownerTagIds]
  },

  methods: {
    removeTag(id) {
      const index = this.editTagIds.indexOf(id)
      this.editTagIds.splice(index, 1)
      this.$emit('input', this.editTagIds)
    },
    applyTag(id) {
      if (!this.editTagIds.includes(id)) {
        this.editTagIds.push(id)
        this.$emit('input', this.editTagIds)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
  }
  > .body {
    width: 60%;
    > .wrap {
      min-height: 33px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-items: center;
      gap: 0 5px;
      box-sizing: border-box;
      > .tag {
        padding: 3px 0;
      }
      > .square-button {
        height: 30px;
        width: 30px;
        font-size: 13px;
        color: #{$pumpkin};
        background-color: white;
        border: solid 2px #{$light-grey};
        border-radius: 6px;
        padding: auto;
      }
    }
  }
}
</style>
